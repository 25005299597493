body {
    font-family: "Ploni Regular", sans-serif;
}

.modal-active {
    overflow: hidden;
}

.lang-he {
    direction: rtl;
}

.lang-he .back-btn {
    transform: rotate(180deg);
    right: 149px;
    left: initial;

    @media (max-width: 1000px) {
        right: 3%;
    }
}

.lang-he .bullet_list span {
    text-align: right;
}

.container {
    max-width: 1600px;
    margin: 0 auto;
    text-align: center;
}

.image-slider {
    position: relative;
    width: 75%;
    height: 269px; /* Adjust the height as needed */
}

.slider-image {
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    left: 0;
    border-radius: 15px;
    opacity: 0;
    transition: opacity 1s ease-in-out;
    object-fit: cover;
}

.slider-image.visible {
    opacity: 1;
}

.back-btn:hover {
    background: #ffc43d;
}

.back-btn {
    background: #ffa927;
    cursor: pointer;
    border-radius: 50%;
    position: absolute;

    width: 39px;
    height: 39px;

    justify-content: center;
    align-items: center;
    display: grid;

    top: 144px;
    right: initial;
    left: 149px;

    z-index: 1;

    border: none;
}

.back-btn:focus {
    outline: 1px solid transparent;
}

.logo-container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
}

.back-btn img {
    width: 30px;
}

.loadingContainer {
    display: flex;
    justify-content: center;
    height: 100%;
}

.header {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-evenly;
    flex-wrap: wrap;
    position: relative;
}

.bullets {
    display: flex;
    align-self: flex-start;
    margin-top: 18px;
}

.bullets:focus {
    outline: 1px solid transparent;
}

.error {
    color: #e15125;
    margin-top: 10px;
    font-weight: bold;
    left: 0;
    width: 100%;
    position: relative;
    top: 60px;
}

.bullet {
    background: #f0f0f0;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    color: #fff;
    font-weight: 700;
    display: flex;
    align-items: center;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
    margin: 0 -2px 0 -2px;
    z-index: 2;
}

.params {
    display: Flex;
    flex-wrap: wrap;
}

.kid-details .details {
    display: flex;
    flex-direction: column;
}

.kid-details .details span {
    font-size: 22px;
    font-weight: 400;
    margin: 5px 0 26px;
}

.param_container .title {
    font-size: 20px;
    font-weight: 400;
    margin-top: 12px;
}

.hairStyle .title {
    text-align: left;
    margin-top: 18px;
}

.lang-he .hairStyle .title {
    text-align: right;
}

.lang-he .gender label {
    font-size: 20px;
}

.lang-he .gender label:focus {
    outline: 1px solid transparent;
}

.param_container .name {
    font-size: 17px;
}

.infinite-progress-bar {
    background: #fbf8f8;
    border-radius: 10px;
    height: 15px;
}

.bar {
    background: #ffba34;
    height: 15px;
    border-radius: 10px;
}

.loading-bar {
    width: 50%;
    margin: 25px 0;
}

.loading-bar .text {
    font-size: 30px;
    margin-top: 15px;
    font-weight: 600;
}

.coverSection .main_text {
    font-size: 30px;
    font-weight: 500;
}

.loading-bar .subtext {
    font-size: 20px;
}

.wizard {
    margin: auto;
}

.wizard p {
    margin: 15px 0 1px;
    font-weight: 500;
}

.wizard img {
    max-width: 170px;
}

.param_container {
    display: flex;
    flex-direction: column;
}

.param_container:focus {
    outline: 1px solid transparent;
}

.cat_list:focus {
    outline: 1px solid transparent;
}

.hairStyle {
    padding: 0 10px;
}

.inner_params {
    margin-top: 9px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 0;
}

.paramField .image,
.paramField .name {
    cursor: pointer;
}

.hairStyle .paramField .image img {
    width: 82px;
    height: 82px;
}

.hairStyle .inner_params {
    margin-top: 29px;
    grid-template-columns: 1fr 1fr 1fr;
}

.inner_params .paramField {
}

.hairStyle .inner_params .paramField {
    margin-bottom: 7px;
}

.stage {
    margin-top: 17px;
}

.stage3 {
    /*display: flex;*/
    /*align-items: flex-start;*/
    /*margin-top: 66px;*/
    /*flex-wrap: wrap;*/
    /*margin-inline-start: 153px;*/

    /*display: flex;*/
    /*!* align-items: flex-start; *!*/
    /*margin-top: 146px;*/
    /*flex-wrap: wrap;*/
    /*!* margin-inline-start: 153px; *!*/
    /*margin: 146px auto;*/
    /*width: 71%;*/
    /*display: grid;*/
    /*grid-template-columns: 50% 5% 44%;*/
}

.stage3 .imageUploaded {
    width: 185px;
    height: 185px;
    margin: 19px auto 0;
}

textarea::placeholder {
    font-family: "Ploni Regular", sans-serif;
    opacity: 1;
    color: #000;
    font-size: 20px;
}

textarea {
    border: 1px solid #ececec;
    height: 200px;
    width: 300px;
    padding: 10px;
    font-family: "Ploni Regular", sans-serif;
    color: #000;
    font-size: 20px;

    outline: none;
}

.stage3 .kid-details {
    border-radius: 10px;
}

.param_container {
    margin-top: 14px;
    border-radius: 4px;
    border: 1px solid rgba(202, 202, 202, 0.25);
}

.right-params {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 620px;
    grid-gap: 5px;
    margin: 0 auto;
}

.line {
    background: #bfbfbf;
    height: 5px;
    width: 100px;
    position: relative;
}

.stage-number {
    display: flex;
    align-items: center;
}

.staricon img {
    width: 35px;
}

.stage_4 .line {
    display: none;
}

.headline {
    display: flex;
    justify-content: center;
    flex-direction: row-reverse;
    align-items: center;
    margin-top: 15px;
}

.headline span {
    margin: 0 10px;
    font-size: 25px;
}

.innerBullet {
    background: #bfbfbf;
    width: 27px;
    height: 27px;
    /*display: flex;*/
    /*align-items: center;*/
    /*justify-content: center;*/
    border-radius: 50%;
    margin: 0 auto;
}

.innerBullet span {
    font-size: 19px;
    display: grid;
    line-height: 1.5;
}

.currentBullet .innerBullet {
    height: 17px;
    width: 17px;
    border: 5px solid #ffc43d;
    border-radius: 50%;
    background: #fff;
}

.completed .innerBullet {
    height: 22px;
    width: 22px;
    border-radius: 50%;
    background: #ffc43d;
    transform: rotate(45deg);
}

.currentBullet span {
    color: #000;
    font-weight: 500;
    line-height: 1;
    margin-top: 1px;
}

.selectedItem {
    background: #ffc43d;
    width: 23px;
    height: 23px;
    z-index: 2;
    position: absolute;
    left: 10px;
    top: 10px;
    border-radius: 50%;
}

.selectedItem:after {
    content: "";
    position: absolute;
    width: 6px;
    height: 3px;
    background-color: #000;
    left: 4px;
    top: 11px;
    transform: rotate(41deg);
}

.selectedItem:before {
    content: "";
    position: absolute;
    width: 3px;
    height: 12px;
    background-color: #000;
    left: 12px;
    top: 6px;
    transform: rotate(45deg);
}

.completed .innerBullet:before {
    content: "";
    position: absolute;
    width: 3px;
    height: 12px;
    background-color: #000;
    left: 11px;
    top: 3px;
}

.completed .innerBullet:after {
    content: "";
    position: absolute;
    width: 5px;
    height: 3px;
    background-color: #000;
    left: 6px;
    top: 12px;
}

.completed span {
    display: none;
}

.templates {
    /*display: flex;*/
    /*flex-wrap: wrap;*/
    /*justify-content: center;*/
}

.template_item {
    /*width: 31%;*/
    /*margin: 10px;*/
    cursor: pointer;
}

.coverPictures {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    flex-wrap: wrap;
    min-height: 264px;
}

.coverPictures .coverImage {
    margin: 0px 15px;
}

.coverPictures .coverImage.fs {
    position: relative;
    width: 250px;
    height: 250px;
    overflow: hidden;
    border-radius: 5px;
}

.coverPictures .coverImage.fs .poki-image-0 {
    width: 200%;
    height: 200%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}

.coverPictures .coverImage.fs .poki-image-1 {
    width: 200%;
    height: 200%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: -100%;
}

.coverPictures .coverImage.fs .poki-image-2 {
    width: 200%;
    height: 200%;
    object-fit: cover;
    position: absolute;
    top: -100%;
    left: 0;
}

.coverPictures .coverImage.fs .poki-image-3 {
    width: 200%;
    height: 200%;
    object-fit: cover;
    position: absolute;
    top: -100%;
    left: -100%;
}

.coverPictures .coverImage.bookIsReady {
    cursor: pointer;
}

.coverPictures .coverImage.bookIsReady.selected img {
    /*border: 4px solid #FFC43D;*/
    /*box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);*/
    /*transition: border 0.3s ease;*/
}

.coverPictures .coverImage.bookIsReady img {
    /*border: 4px solid transparent;*/
    /*transition: border 0.3s ease;*/
}

.coverPictures img {
    width: 250px;
    height: 250px;
    border-radius: 15px;
}

.stage2-title {
    font-size: 38px;
    font-weight: 400;
}

.information {
    margin-top: 20px;
}

.information .title {
    font-size: 31px;
    margin-top: 46px;
}

.information .sub-text {
    font-size: 22px;
}

.stage2 .form {
    margin-top: 23px;
}

.horizontal-line-stage2 {
    max-width: 256px;
    height: 1px;
    margin: 18px auto 0 auto;
    background: rgba(0, 0, 0, 0.12);
}

.form {
    margin-top: 25px;
}

.gender {
    display: flex;
    justify-content: center;
    border: 1px solid #cacaca;
    width: fit-content;
    padding: 5px;
    border-radius: 4px;

    &.error-gender-lo {
        border: 1px solid #ff0000ff;
        box-shadow: inset 0px 0px 0px 1px #ff0000ff;
    }
}

input[type="radio"] {
    appearance: none;
    display: none;
}

.gender label {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: inherit;
    width: 62px;
    height: 40px;
    text-align: center;
    border-radius: 4px;
    overflow: hidden;
    transition: linear 0.3s;
    color: #000;
    cursor: pointer;

    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.gender input[type="radio"]:checked + label {
    background-color: #ffc43d;
    color: #000;
    font-weight: 400;
    transition: 0.3s;
}

.paramField img {
    box-sizing: border-box;
}

.paramField input[type="radio"]:checked + label img {
    border: 4px solid #ffc43d;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    transition: border 0.3s ease;
}

.paramField input[type="radio"] + label img {
    border: 4px dotted transparent;
    border-radius: 50%;
    box-sizing: border-box;
}

/*.form input[type='text']{*/
/*    border-radius: 10px;*/
/*    border: 1px solid #CACACA;*/
/*    background: #FFF;*/
/*    width: 219px;*/
/*    height: 22px;*/
/*    padding: 10px;*/

/*    color: #000;*/
/*    font-size: 20px;*/
/*    font-style: normal;*/
/*    font-weight: 400;*/
/*    line-height: normal;*/

/*    display: block;*/
/*    margin: 13px auto;*/
/*}*/

.MuiFormLabel-root.MuiInputLabel-root.Mui-focused {
    color: #ffc43d;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #ffc43d !important;
}

.popup {
    position: fixed;
    top: 20vh;
    right: 0;
    left: 0;
    background: #fff;
    opacity: 0;

    transition: opacity 0.3s ease;

    visibility: hidden;
    margin: 0 auto;
    border: 1px solid #b5b5b5;
    border-radius: 15px;
    padding: 25px;
}

.stage2 .popup {
    top: 40vh;
    padding: 0;

    @media (max-width: 480px) {
        top: 440px;
    }
}

.exit_popup img {
    width: 25px;
}

.exit_popup {
    position: absolute;
    right: 36px;
    top: 21px;
    cursor: pointer;
}

.ageSelection {
    width: 388px;
}

.ageSelection .exit_popup {
    position: absolute;
    right: 0px;
    top: 9px;
    cursor: pointer;
}

.bullet_list li {
    list-style-type: none;
    margin: 10px 0px;
    align-items: center;
    display: flex;
}

.bullet_list span {
    margin-top: 5px;
    text-align: left;
}

.bullet_list li img {
    /*margin-left: 8px;*/
    margin-inline-end: 8px;
}

.bullet_list {
    text-align: right;
    display: grid;
    align-content: center;
}

.picture_example {
    max-width: 751px;
    border-radius: 50px;

    @media (max-width: 1000px) {
        width: 90%;
    }

    @media (max-width: 480px) {
        width: 90%;
        z-index: 5;
    }
}

.popup_container {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 28px;
    padding: 0 32px;

    &.preebooks-modal {
        display: block;
    }
}

.openSelection {
    opacity: 1;
    visibility: visible;
}

.age {
    padding: 38px 0 24px 0;
    color: #000;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.range-input {
    width: 74%;
    appearance: none;
    background: #000;
    height: 1px;
    border-radius: 4px;
    outline: none;
    opacity: 1;
    transition: opacity 0.2s;
}

.range-input::-webkit-slider-thumb {
    appearance: none;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #ffc43d;
    cursor: pointer;
}

.range-input::-webkit-scrollbar {
    width: 10px;
}

.range-input::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;
}

.range-input::-webkit-scrollbar-track {
    background: transparent;
}

.sub-text {
    font-size: 20px;
}

.button {
    background: #ffa927;
    color: #000;
    font-weight: bold;
    border-radius: 8px;
    padding: 0 25px;
    display: flex;
    align-items: center;
    width: fit-content;
}

button:disabled,
button[disabled] {
    background: #cfcbc4;
}

.checkout_btn {
    justify-content: center;
    margin: 66px auto 42px;
    padding: 17px 40px;
    font-size: 30px;
}

.middleOptions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 13px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.ageField {
    border-radius: 10px;
    border: 1px solid #cacaca;
    /*margin-right: 10px;*/
    text-align: center;

    width: 71px;
    height: 30px;
    padding: 10px;

    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: grid;
    align-content: center;
}

.selectAgeBtn {
    cursor: pointer;
    justify-content: center;
    margin: 36px 14px 16px;
    padding: 11px 45px;
    color: #000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.understandBtn {
    margin: 31px auto 15px;
    padding: 6px 32px;
    font-size: 25px;
}

.upload label {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    flex-direction: column;
}

.imageUploaded img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

/*.imageUploaded span {*/
/*    color: #fff;*/
/*    position: relative;*/
/*    top: -3px;*/
/*    visibility: hidden;*/
/*}*/

.selectedImage {
    margin-top: 25px;
    margin-bottom: 25px;
}

.upload label input {
    display: none;
}

.uploadbox {
    /*background: #FFC670;*/
    /*border-radius: 50%;*/
    width: 100%;
    height: 169px;
    position: relative;

    @media (max-width: 1000px) {
        height: auto;
    }
}

.uploadbox:focus,
.imageUploaded:focus {
    outline: 1px solid transparent;
}

.uploadbox svg {
    width: 62px;
    height: 62px;
    fill: #fff;
}

.imageUploaded {
    width: 169px;
    height: 169px;
}

.imageUploaded.stage2-imageUploaded {
    margin: 19px auto 0;
    width: 185px;
    height: 185px;

    img {
        cursor: pointer;
    }
}

.imageUploaded .image {
    width: 100%;
    height: 100%;
}

.uploadbox .image {
    margin-top: 3px;
}

.explain {
    margin: 16px 0 0 0;
}

.explain p {
    margin: 0;
    font-size: 17px;
    font-weight: 400;
    line-height: 20px;
    color: #837373;
}

.size {
    font-size: 14px;
    margin-top: 28px;
}

.uploader {
    border: 1px dashed #ffb02a;
    border-radius: 10px;
    max-width: 402px;
    padding-top: 24px;
    padding-bottom: 11px;

    @media (max-width: 480px) {
        /*border: 1px dashed transparent;*/
    }
}

.uploader.poilz {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 0 20px 0;
    margin: auto;
}

.notes {
    margin: 10px 0 0;
    font-size: 13px;
}

.note-icon {
    display: inline;
    vertical-align: middle;
    /*margin-left: 7px;*/
    margin-inline-end: 7px;
}

.example_btn {
    color: #5d35ff;
    cursor: pointer;
    font-size: 18px;
    width: max-content;
    margin: 0 auto 12px;
}

.example_btn:hover {
    color: #2a00d3;
}

.example_btn:focus {
    outline: 1px solid transparent;
}

a {
    color: #000;
    text-decoration: none;
}

.button img {
    margin-right: 10px;
}

.currentCat {
    border-bottom: 4px solid #ffa927;
}

.template_item .magnify {
    position: absolute;
    top: 0;
    cursor: pointer;
    right: 0;
    z-index: 2;
    background: #fff;
    border-radius: 5px;
    text-align: center;
    padding-top: 2px;
    padding-bottom: 2px;
    margin: 8px;
    width: 23px;
    height: 18px;
}

.template_item .info,
.poikop .info {
    position: absolute;
    top: 4px;
    right: 21px;
    z-index: 2;
    cursor: pointer;
    border-radius: 50%;
    text-align: center;
    width: 17px;
    height: 17px;
    background: #ffa927;

    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);

    color: #fff;
    font-size: 12px;
    line-height: 17px;
}

.template_item .info:hover,
.poikop .info:hover {
    background: #ffc43d;
}

.template_item .info::before,
.poikop .info::before {
    content: "i";
    font-size: 14px;
    position: absolute;
    top: 1px;
    text-align: center;
    width: 100%;
    left: 0;
    color: #fff;
    font-weight: 900;
}

.template_item .arrow-dz {
    position: absolute;
    top: 10px;
    right: 8px;

    &::after {
        content: "";
        position: absolute;
        width: 9px;
        height: 1px;
        transform: rotate(47deg);
        background: rgb(255, 196, 61);
    }

    &::before {
        content: "";
        width: 9px;
        height: 1px;
        transform: rotate(135deg);
        position: absolute;
        top: 6px;
        background: rgb(255, 196, 61);
    }
}

.template_item .title {
    position: absolute;
    bottom: 0;
    color: #fff;
    /*margin-right: 10px;*/
    margin-inline-start: 10px;
    margin-bottom: 5px;
    font-size: 20px;
    z-index: 2;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

.imageBox {
    position: relative;
    width: 100%;
    padding-bottom: 65%;
    overflow: hidden;
    border-radius: 28px;
    border: 4px solid transparent;
    box-sizing: border-box;

    .info-wrapper {
        position: absolute;
        top: 10px;
        right: 14px;
        background: #fff;
        border-radius: 20px;
        width: 43px;
        height: 25px;
        z-index: 1;
    }
}

.selectedContainer .imageBox {
    border: 4px solid #ffc43d;
}

.imageBox .template-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 0 -10px;
}

.cat_list {
    display: Flex;
    justify-content: center;
    margin-top: 16px;
    margin-bottom: 25px;
}

.wand-icon {
    margin-inline-start: 6px;
}

.cat_list .cat_item {
    margin: 0px 10px;
    cursor: pointer;
    color: #000;
    text-align: center;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.pizeda {
    z-index: 10000;
}

/* mobile */

@media (max-width: 1000px) {
    .stage3 .kid-details {
        margin: 20px auto;
    }

    .stage3 {
        margin: auto;
        width: 100%;
    }

    .back-btn {
        left: 3%;
    }

    .paramField input[type="radio"] + label img {
        width: 75%;
    }

    .hairStyle .paramField .image img {
        width: 150px;
        height: 150px;
    }

    .kid-details .details span {
        margin: 5px 0 4px;
    }
}

@media (max-width: 767px) {
    .header .line {
        width: 45px;
    }

    .stage3 .kid-details {
        border: none;
    }

    .coverPictures {
        flex-wrap: wrap;
    }

    .coverPictures .coverImage {
        width: 45%;
        margin: 5px;
    }

    .coverPictures img {
        width: 100%;
        height: 150px;
    }

    .next-button,
    .selectAgeBtn,
    .checkout_btn {
        margin-top: 15px;
    }

    .uploader {
        padding-top: 5px;
        padding-bottom: 5px;
        margin-top: 15px;
        max-width: 455px;
    }

    .exit_popup {
        right: 30px;
        top: 30px;
    }

    .loading-bar {
        width: 100%;
    }

    .params {
        justify-content: center;
    }

    .param_container {
        margin-right: 0px;
    }

    .personalise_btn {
        margin: 5vh;
        margin-bottom: 50px;
    }

    .popup_container {
        flex-wrap: wrap;
        justify-content: center;
    }

    .popup {
        margin: 5px;
        top: 10vw;
        padding: 15px;
    }

    .stage3 {
        margin-top: 10px;
    }

    .ageSelection {
        margin: 0 auto;
        top: 25vh;
    }

    .right-params {
        width: 100%;
    }

    .hairStyle .paramField .image img {
        width: 100px;
        height: 100px;
    }
}

@media (max-width: 480px) {
    .infinite-progress-bar {
        margin: 0 2%;
    }

    .information .title {
        margin-top: 26px;
    }

    .coverPictures {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 3%;
        margin: 20px 0 10px 0;
    }

    .coverPictures .coverImage {
        width: 100%;
        margin: 0px;
    }

    .imageUploaded.stage2-imageUploaded {
        margin: 19px auto 0;
        width: 165px;
        height: 165px;
    }

    .stage {
        margin-top: 2px;
    }

    .logo-container .img {
        width: 150px;
    }

    .notes {
        /*margin-top: 19px;*/
    }

    .stage2-title {
        margin: 5px 0 0 0;
        font-size: 40px;
    }

    .uploader {
        max-width: 355px;
    }

    .back-btn {
        top: 52px;
    }

    .stage3 .kid-details {
        margin-top: 4px;
    }

    .stage3 .imageUploaded {
        margin-top: 0;
        width: 165px;
        height: 165px;
    }

    .hairStyle .paramField .image img {
        width: 150px;
        height: 150px;
    }

    .cat_list .cat_item {
        margin: 0 5px;
        font-size: 20px;
    }

    .exit_popup {
        right: 10px;
        top: 10px;
    }

    .popup_container {
        margin-top: 0;
        padding: 0;
    }

    .bullet_list {
        margin: 0;
    }

    .lang-en .cat_list .cat_item {
        font-size: 18px;
    }

    .lang-en .back-btn {
        left: 1%;
    }

    .coverPictures .coverImage.fs {
        width: 100%;
        height: 100%;
    }

    /*.uploadbox{*/
    /*    width: 100px;*/
    /*    height: 100px;*/
    /*}*/
    /*.uploadbox svg{*/
    /*    width: 42px;*/
    /*    height: 42px;*/
    /*}*/
    /*.uploadbox .image {*/
    /*    margin-top: 26px;*/
    /*}*/
}

@media (max-width: 340px) {
    .cat_list .cat_item {
        font-size: 16px;
    }
}

@keyframes loadingSpinner {
    0% {
        transform: rotate(0deg) translateZ(0);
    }
    100% {
        transform: rotate(360deg) translateZ(0);
    }
}

.lang-en,
.lang-da {
    .template_item .arrow-dz {
        right: 16px;
    }

    @media (max-width: 480px) {
        h3.several-things {
            font-size: 20px;
        }

        .headline.stage2-title {
            font-size: 27px;
        }

        .headline.stage4 {
            span {
                font-size: 25px;
            }
        }
    }
}

.MEDIA{
    .stage{
     margin-top: 0;
    }
}
